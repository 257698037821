import React, { useEffect, useState } from "react";
import { ref as databaseRef, onValue, update, remove } from "firebase/database";
import { database } from "../firebaseConfig";
import Papa from "papaparse";

function InstaAccounts() {
  const [accounts, setAccounts] = useState([]);
  const [file, setFile] = useState(null);
  const [existingUrls, setExistingUrls] = useState(new Set());

  useEffect(() => {
    const accountsRef = databaseRef(database, "Socials/Instagram");
    onValue(accountsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const accountsList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        const urls = new Set(accountsList.map((account) => account.instagram_url));
        setExistingUrls(urls);

        setAccounts(accountsList);
      }
    });
  }, []);


  const [statusCounts, setStatusCounts] = useState({ fresh: 0, targeted: 0, retargeted: 0 });

useEffect(() => {
  const accountsRef = databaseRef(database, "Socials/Instagram");
  onValue(accountsRef, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      const accountsList = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));

      const urls = new Set(accountsList.map((account) => account.instagram_url));
      setExistingUrls(urls);
      setAccounts(accountsList);

      // Berechne die Anzahl der Accounts pro Status
      const statusSummary = accountsList.reduce(
        (acc, account) => {
          acc[account.status] = (acc[account.status] || 0) + 1;
          return acc;
        },
        { fresh: 0, targeted: 0, retargeted: 0 }
      );

      setStatusCounts(statusSummary);
    }
  });
}, []);


  // 🔥 Toggle Status (fresh → targeted → retargeted → fresh)
  const toggleStatus = (account) => {
    let newStatus;
    if (account.status === "fresh") newStatus = "targeted";
    else if (account.status === "targeted") newStatus = "retargeted";
    else newStatus = "fresh"; // Wenn retargeted → zurück zu fresh

    const accountRef = databaseRef(database, `Socials/Instagram/${account.id}`);
    update(accountRef, { status: newStatus })
      .then(() => console.log(`✅ ${account.instagram_url} Status geändert zu ${newStatus}`))
      .catch((error) => console.error("Fehler beim Ändern des Status: ", error));
  };

  // 🔥 CSV Upload-Funktion
  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const uploadCSV = () => {
    if (!file) return alert("Bitte eine CSV-Datei auswählen!");

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const data = results.data;
        let addedCount = 0;
        let skippedCount = 0;

        for (const row of data) {
          const instagramUrl = row.instagram_url?.trim();
          const account = row.account?.trim();
          const status = row.status?.trim() || "fresh"; // Standardwert "fresh"

          if (!instagramUrl || !account || !status) {
            skippedCount++;
            continue;
          }

          if (existingUrls.has(instagramUrl)) {
            console.log(`⚠️ Übersprungen (doppelt): ${instagramUrl}`);
            skippedCount++;
            continue;
          }

          const newAccountRef = databaseRef(database, `Socials/Instagram/${Date.now()}`);
          await update(newAccountRef, { instagram_url: instagramUrl, account, status });

          existingUrls.add(instagramUrl);
          addedCount++;
        }

        alert(`✅ CSV-Upload abgeschlossen! Neu hinzugefügt: ${addedCount}, Übersprungen: ${skippedCount}`);
      },
    });
  };

  return (
    <div className="insta-accounts-container">
      <div className="status-overview">
  <h3>📊 Status-Übersicht</h3>
  <p>🔴 Fresh: {statusCounts.fresh}</p>
  <p>🟢 Targeted: {statusCounts.targeted}</p>
  <p>🔵 Retargeted: {statusCounts.retargeted}</p>
  <p>📌 Gesamt: {statusCounts.fresh + statusCounts.targeted + statusCounts.retargeted}</p>
</div>
      <h2>📢 Instagram Accounts Übersicht</h2>

      <div>
        <h3>📂 CSV hochladen</h3>
        <input type="file" accept=".csv" onChange={handleFileUpload} />
        <button onClick={uploadCSV}>🚀 Hochladen</button>
      </div>

      <table>
        <thead>
          <tr>
            <th>Instagram URL</th>
            <th>Account</th>
            <th>Status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <tr key={account.id}>
              <td>
                <a href={account.instagram_url} target="_blank" rel="noopener noreferrer">
                  {account.instagram_url}
                </a>
              </td>
              <td>{account.account || "N/A"}</td>
              <td
                style={{
                  color:
                    account.status === "retargeted"
                      ? "blue"
                      : account.status === "targeted"
                      ? "green"
                      : "red", // Fresh = Rot
                  fontWeight: "bold",
                }}
              >
                {account.status}
              </td>
              <td>
                <button onClick={() => update(databaseRef(database, `Socials/Instagram/${account.id}`), { status: "fresh" })}>
                  Set to Fresh
                </button>
                <button onClick={() => update(databaseRef(database, `Socials/Instagram/${account.id}`), { status: "targeted" })} style={{ marginLeft: "10px" }}>
                  Set to Targeted
                </button>
                <button onClick={() => update(databaseRef(database, `Socials/Instagram/${account.id}`), { status: "retargeted" })} style={{ marginLeft: "10px" }}>
                  Set to Retargeted
                </button>
                <button onClick={() => toggleStatus(account)} style={{ marginLeft: "10px" }}>
                  Toggle
                </button>
                <button onClick={() => remove(databaseRef(database, `Socials/Instagram/${account.id}`))} style={{ marginLeft: "10px", backgroundColor: "red", color: "white" }}>
                  Löschen
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default InstaAccounts;
